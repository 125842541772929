import styled from "@emotion/styled";
import { Flex, Pagination, Table, Text } from "@mantine/core";
import { WlWithEslViewGet200ResponseRowsInnerActionTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { useAuthWhoamiGet } from "api/useAuthWhoamiGet";
import { wmsLogs } from "api/wms/useWmsQuery";
import { WmsTableRow } from "components/pages/wmsLogs/WmsTableRow";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import timeUtil from "utils/timeUtil";
import { useToggle } from "utils/useToggle";

export const WMSLogs = () => {
  const [activePage, setPage] = useState(1);
  const { isOpen, toggle, reset } = useToggle();
  const { data: user } = useAuthWhoamiGet();

  const userCode = user?.code;
  const { data } = useQuery(
    wmsLogs.get({
      populate: ["locationName", "lot", "item", "batch"],
      query: {
        $and: [
          {
            actionType: {
              $eq: WlWithEslViewGet200ResponseRowsInnerActionTypeEnum.BATCH,
            },
            creatorUserCode: {
              $eq: userCode,
            },
          },
        ],
      },
      page: activePage,
      pageSize: 10,
    })
  );

  // 페이지 이동시 토글 초기화
  useEffect(() => {
    reset();
  }, [activePage]);

  return (
    <Flex direction={"column"} color="white" p="md">
      <Table>
        <Thead>
          <Tr>
            {[
              "",
              "로그번호",
              "실적종류",
              "품목명",
              "품목코드",
              "로트명",
              "규격",
              "수량",
              "출고창고",
              "입고창고",
              "작업자",
              "생성일",
            ].map((text, index) => (
              <Th seq={index}>
                <Text fz="md" fw={500} span>
                  {text}
                </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data?.data?.rows
            ?.filter((log) => log.actionParam?.params?.fromLocationCode)
            ?.map((log, index) => {
              return (
                <>
                  <Tr key={log.id}>
                    <Td>
                      {!isOpen(index.toString()) ? (
                        <IconPlus
                          onClick={() => toggle(index.toString())}
                          style={{ verticalAlign: "middle", cursor: "pointer" }}
                        />
                      ) : (
                        <IconMinus
                          onClick={() => toggle(index.toString())}
                          style={{ verticalAlign: "middle", cursor: "pointer" }}
                        />
                      )}
                    </Td>
                    <Td>
                      <Text align="right">{log.id}</Text>
                    </Td>
                    <Td>
                      <Text
                        color={
                          log.actionParam?.params?.targetLocationName
                            ? "blue"
                            : "red"
                        }
                      >
                        {log.actionParam?.params?.targetLocationName
                          ? "벌크이동"
                          : "벌크출고"}
                      </Text>
                    </Td>
                    <Td>
                      <Text>
                        {log.batchLotInfo &&
                          log.batchLotInfo[0]?.itemInfo?.name}
                      </Text>
                    </Td>
                    <Td>
                      <Text>
                        {log.batchLotInfo &&
                          log.batchLotInfo[0]?.itemInfo?.code}
                      </Text>
                    </Td>
                    <Td>
                      <Text>
                        {log.batchLotInfo && log.batchLotInfo[0]?.lotInfo?.name}
                      </Text>
                    </Td>
                    <Td>
                      <Text>
                        {log.batchLotInfo &&
                          log.batchLotInfo[0]?.itemInfo?.spec}
                      </Text>
                    </Td>
                    <Td>
                      <Text align="right">
                        {log.batchLotInfo && log.batchLotInfo[0]?.quantity}
                      </Text>
                    </Td>
                    <Td>
                      <Text>{log.actionParam?.params?.sourceLocationName}</Text>
                    </Td>
                    <Td>
                      <Text>
                        {log.actionParam?.params?.targetLocationName ?? ""}
                      </Text>
                    </Td>
                    <Td>
                      <Text>{log.creatorUserCode}</Text>
                    </Td>
                    <Td>
                      <Text>{timeUtil(log.createdAt)}</Text>
                    </Td>
                  </Tr>
                  {isOpen(index.toString()) && (
                    <Tr>
                      <Td colSpan={4}>
                        <WmsTableRow lotInfo={log.actionParam?.params?.items} />
                      </Td>
                    </Tr>
                  )}
                </>
              );
            })}
        </Tbody>
      </Table>
      <Flex justify={"center"} pt="xl">
        <Pagination
          align="'center'"
          onChange={setPage}
          value={activePage}
          total={data?.data?.totalPages ?? 0}
        />
      </Flex>
    </Flex>
  );
};

const Thead = styled.thead`
  width: 100%;
`;

export const Tbody = styled.tbody`
  width: 100%;
  color: white;
`;
const Tr = styled.tr`
  display: flex;
  width: 100%;
  height: auto;
`;

const Th = styled.th<{ seq?: number; flex?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  gap: 10px;
  white-space: pre-line;
  word-break: break-word;
`;

const Td = styled.td<{ seq?: number; flex?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  align-self: stretch;
`;
