import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  MasterApiWlWithEslViewGetRequest,
  MasterApiWlWithEslViewWlWithEslViewIdGetRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { MasterInstance } from "instance/axios";

export const wmsLogs = createQueryKeys("wmsLogs", {
  all: null,
  get: (params: MasterApiWlWithEslViewGetRequest) => {
    const query = { ...params };
    if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => MasterInstance.wlWithEslViewGet(query),
    };
  },
  detail: (params: MasterApiWlWithEslViewWlWithEslViewIdGetRequest) => {
    return {
      queryKey: [params],
      queryFn: () => MasterInstance.wlWithEslViewWlWithEslViewIdGet(params),
    };
  },
});
