import styled from "@emotion/styled";
import { Button, Flex, Loader, Table, Text, TextInput } from "@mantine/core";
import { useForm, UseFormReturnType } from "@mantine/form";
import { ProductionActionApiWorksBulkInputPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import useInventoriesGetQuery from "api/inventories/useInventoriesGetQuery";
import { INVENTORIES_KEY, mutateInventories } from "api/inventories/useInventoriesQuery";
import { WORK_LOGS_KEY } from "api/logs/useWorksLogsQuery";
import { useModal } from "context/ModalStackManager";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { customNotification } from "utils/notificationShow";
import { RawMaterialFormRow } from "./RawMaterialFormRow";
import { getPrinterCodeByUserWithWhoami } from "utils/checkAuth";
import axios from "axios";

export interface WorkInputFormProps {
    workData: any;
    lotId?: number;
    onInputSuccess?: () => void;
}

interface WorkBulkInputData {
    lotId: number;
    itemCode?: string;
    itemName?: string;
    lotName?: string;
    lotExpireDate?: string;
    locationCode?: string;
    quantity?: string;
}

export interface RawMaterialInputAllFormProps {
    formData: WorkBulkInputData;
    seq: number;
    onChange: (index: number, quantity: string) => void;
    onDelete: (index: number) => void;
}

export interface RoutingBomProps {
    itemCode: string;
    routingCode: string;
    item: {
        name: string;
    };
}

interface FormValues {
    worksBulkInputPutRequest: WorkBulkInputData[];
}

export const RawMaterialReturnInputForm = ({
    workData,
    onInputSuccess
}: WorkInputFormProps) => {
    const { closeModal } = useModal();    
    const [barcodeLoading, setBarcodeLoading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // 모달 상태 추가
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const [sourceLocationCode, setSourceLocationCode] = useState("");
    const [targetLocationCode, setTargetLocationCode] = useState("");

    const { mutate: InventoriesAllMutate, isLoading } = useMutation(
        (params: ProductionActionApiWorksBulkInputPutRequest) =>
            mutateInventories.workInputAll(params).mutationFn(undefined),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(INVENTORIES_KEY);
                queryClient.invalidateQueries(WORK_LOGS_KEY);
            }
        }
    )

    const form: UseFormReturnType<FormValues> = useForm<FormValues>({
        initialValues: {
            worksBulkInputPutRequest: []
        },
    });

    const handleConfirm = async () => {
        setIsModalOpen(false); // 모달 닫기
        onSubmit(true); // 원부자재 출력 실행
    };

    const handleSkip = () => {
        setIsModalOpen(false); // 모달 닫기
        onSubmit(false); // 반환 로직 실행
    };

    const { data } = useInventoriesGetQuery(
        {
            query: {
                $and: [
                    {
                        itemCode: workData?.routingData?.routingBoms?.map((value: RoutingBomProps) => value.itemCode)
                    },
                    {
                        locationCode:
                            workData?.locationSetting?.toLocationCode?.code ??
                            workData?.routingData?.operation?.toLocationCode ??
                            workData?.productionPlan?.routingsData.find(
                                (data: any) => data.code === workData.routingCode
                            ).operation.toLocationCode,
                    },
                    {
                        quantity: { $gt: 0 },
                    }
                ],
            }
        }
    );

    useEffect(() => {
        setSourceLocationCode(            
            workData?.equipment?.toLocationCode ??
            workData?.locationSetting?.toLocation?.code ??
            workData?.currentRoutingOutsourceData?.toLocationCode ??
            workData?.routingData?.operation?.toLocationCode
        );
        setTargetLocationCode(
            // workData?.currentRoutingOutsourceData?.toLocationCode ??
            workData?.equipment?.fromLocationCode ??
            workData?.locationSetting?.fromLocation?.code ??
            workData?.routingData?.operation?.fromLocationCode ??
            workData?.productionPlan?.routingsData.find(
                (data: any) => data.code === workData.routingCode
            ).operation.fromLocationCode);
    }, [workData]);

    useEffect(() => {
        if (data?.data?.rows) {
            const mappedData = data.data.rows
                .filter((row) => row.lotId !== undefined) // lotId가 undefined인 항목 필터링
                .map((row) => ({
                    lotId: row.lotId as number, // lotId가 undefined가 아님을 보장
                    itemCode: row.itemCode || "-", // 기본값 설정
                    itemName: row.itemName || "-", // 기본값 설정
                    lotName: row.lot?.name || "-",
                    lotExpireDate: row.lot?.expiration || "-",
                    locationCode: row.locationCode || "-", // 기본값 설정
                    quantity: row.quantity || "0", // 기본값 설정
                }));
    
            form.setFieldValue("worksBulkInputPutRequest", mappedData);
        }
    }, [data]);

    const handleRowChange = (index: number, quantity: string) => {
        form.setFieldValue(`worksBulkInputPutRequest.${index}.quantity`, quantity);
    }

    const handleRowDelete = (index: number) => {
        form.removeListItem('worksBulkInputPutRequest', index)
    }

    const onSubmit = async (print?: boolean) => {
        setBarcodeLoading(true);
        try {
            const request = form.values.worksBulkInputPutRequest.map((data: WorkBulkInputData) => ({
                workId: workData.id,
                lotId: data.lotId,
                targetLocationCode,
                sourceLocationCode,
                quantity: data.quantity
            }));

            await InventoriesAllMutate(
                {
                    worksBulkInputPutRequest: {
                        workLotPairs: request
                    }
                },
                {
                    onSuccess: async () => {
                        customNotification.success({
                            message: t("원/부자재가 성공적으로 반환되었습니다."),
                        });
                        if (print) {
                            onSeparatePallet();
                        }
                        onInputSuccess && onInputSuccess(); // 실적 저장시 원부자재 투입이 된다면 실적저장 함수를 실행
                        closeModal(form.values);
                    },
                    onError: (error: any) => {
                        customNotification.error({
                            message: error?.response?.data?.message ?? t("원/부자재 반환에 실패하였습니다.")
                        });
                    }
                }
            );
        } catch (e) {
            customNotification.error({
                message: t("원/부자재 반환에 실패하였습니다."),
            });
        } finally {
            setBarcodeLoading(false);
        }
    };

    const onSeparatePallet = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_COMPANY_BARCODE_ADDRESS_INVENTORY}`, {
                company_code: process.env.REACT_APP_COMPANY_CODE,
                lot_name: form.values.worksBulkInputPutRequest.map((lot) => lot.lotName), // lotName 배열 생성
                printer_code: await getPrinterCodeByUserWithWhoami(),
                quantity: 1, // 몇 장 뽑을 것인지에 대한 quantity 정보
                location_code: form.values.worksBulkInputPutRequest.map(() => (
                    workData?.fromLocationCode
                    ?? workData?.equipment?.fromLocationCode
                    ?? workData?.routingOutsourceData?.from_location_code
                    ?? workData?.routingData?.operation?.fromLocationCode
                )), // location_code를 각 항목에 대해 반복 생성
                item_code: form.values.worksBulkInputPutRequest.map((lot) => lot.itemCode), // itemCode 배열 생성
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = response.data;
            console.log(data);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    if (isLoading || barcodeLoading) {
        return (
            <Flex w="100%" h="100%" justify="center" align="center">
                <Loader size="30rem" />
            </Flex>
        );
    }

    return (
        <>
            <PrintModal isOpen={isModalOpen}>
                <div className="modal-content">
                    <Text>{t("원부자재를 출력하시겠습니까?")}</Text>
                    <div className="modal-footer">
                        <Button onClick={handleConfirm} color="blue">
                            {t("예")}
                        </Button>
                        <Button onClick={handleSkip} color="gray">
                            {t("아니오")}
                        </Button>
                    </div>
                </div>
            </PrintModal>
            <Flex w="80rem" direction="column" gap="md" mah="50rem">
                <Flex gap="md">
                    <TextInput size="xl" label={<Text fz="1.5rem">{t("출고로케이션")}</Text>} disabled value={targetLocationCode} />
                    <TextInput size="xl" label={<Text fz="1.5rem">{t("입고로케이션")}</Text>} disabled value={sourceLocationCode} />
                </Flex>
                <Table>
                    <thead>
                        <tr>
                            <Th width={7}><Text fz="2rem">{t("순서")}</Text></Th>
                            <Th width={17}><Text fz="2rem">{t("품목코드")}</Text></Th>
                            <Th width={17}><Text fz="2rem">{t("품목명")}</Text></Th>
                            <Th width={14}><Text fz="2rem">{t("로트명")}</Text></Th>
                            <Th width={18}><Text fz="2rem">{t("로트유효기한")}</Text></Th>
                            <Th width={13}><Text fz="2rem">{t("로트 위치")}</Text></Th>
                            <Th width={14}><Text fz="2rem">{t("수량")}</Text></Th>
                            <Th width={8}>&nbsp;</Th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            form.values.worksBulkInputPutRequest?.map((formData: WorkBulkInputData, index) =>
                                <RawMaterialFormRow formData={formData} seq={index} key={formData.lotId + index} onChange={handleRowChange} onDelete={handleRowDelete} />
                            )
                        }
                        {
                            form.values.worksBulkInputPutRequest && form.values.worksBulkInputPutRequest.length === 0 &&
                            <tr>
                                <Td colSpan={7} width={100}>
                                    <Text ta="center" fz="2rem">{t("생산 가능한 재고가 없습니다.")}</Text>
                                </Td>
                            </tr>
                        }
                    </tbody>
                </Table>
                <Flex justify="flex-end" gap="md">
                    <Button size="xl" color="gray" onClick={() => closeModal({})}>{t("취소")}</Button>
                    <Button size="xl"
                        disabled={form.values.worksBulkInputPutRequest.length === 0}
                        onClick={() => setIsModalOpen(true)}>{t("원부자재 일괄 반환")}</Button>
                </Flex>
            </Flex>
        </>
    )
}

const PrintModal = styled.div<{ isOpen: boolean }>`
    display: ${(props) => (props.isOpen ? "flex" : "none")};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .modal-content {
        background: white;
        padding: 2rem;
        border-radius: 8px;
        width: 30rem;
        max-width: 90%;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .modal-header {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .modal-footer {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        margin-top: 1rem;
    }
`;

const Th = styled.th<{ width?: number }>`
    width: ${(props) => (props.width ? `${props.width}%` : "auto")};
    padding: 7px 10px;
    align-items: flex-start;
`

export const Td = styled.td<{ width?: number }>`
    width: ${(props) => (props.width ? `${props.width}%` : "auto")};
    padding: 7px 10px;
    align-items: flex-start;
`