import styled from "@emotion/styled";
import { Button, Select, Text } from "@mantine/core";
import { useAuthWhoamiGet } from "api/useAuthWhoamiGet";
import { menuList } from "constants/menuList";
import { useLocalStorage } from "context/LocalStorageProvider";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { theme } from "styles/theme";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    workId,
    setEquipmentCode,
    setFromLocationCode,
    setPrintCode,
    setEquipmentName,
  } = useLocalStorage();
  const { i18n, t } = useTranslation();

  const changeLanguage = (value: string | undefined) => {
    i18n.changeLanguage(value);
  };

  const { refetch } = useAuthWhoamiGet({
    onSuccess: (data) => {
      setEquipmentCode(data.equipmentCode);
      setFromLocationCode(data.fromLocationCode);
      setPrintCode(data.printerCode);
      setEquipmentName(data.equipmentName);
    },
  });

  const handleLogout = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/auth/logout`, {
      method: "POST", // HTTP 메소드 지정
      body: JSON.stringify({ username: localStorage.getItem("name") }),
      headers: {
        "Content-Type": "application/json", // 내용 유형을 JSON으로 설정
      },
    });

    navigate("/");
    localStorage.clear();
    window.location.reload();
  };

  return (
    <nav className="sidebar">
      <SideBarHeader>
        <Text c={theme.colors?.white?.[0]} size={"md"}>
          {"생산 POP"}
        </Text>
      </SideBarHeader>
      <SideBarBody className="sidebar-body">
        {menuList.map((menu, index) => {
          return (
            <NavLink
              style={{
                width: "100%",
              }}
              onClick={() => refetch()}
              to={menu.name === "POP" ? `${menu.id}/${workId}` : menu.id}
              key={index}
            >
              <Button
                style={{
                  backgroundColor: "inherit",
                  border: `1px solid ${theme.colors?.blue?.[6]}`,
                  width: "100%",
                  padding: "0.0625rem 1.625rem",
                  textAlign: "center",
                  height: "3.375rem",
                  fontSize: "1.125rem",
                  background:
                    (menu.name === "POP" ? `${menu.id}/${workId}` : menu.id) ===
                    location.pathname
                      ? theme.colors?.white?.[6]
                      : "inherit",
                  color:
                    (menu.name === "POP" ? `${menu.id}/${workId}` : menu.id) ===
                    location.pathname
                      ? theme.colors?.blue?.[6]
                      : theme.colors?.blue?.[6],
                }}
              >
                {t(menu.name)}
              </Button>
            </NavLink>
          );
        })}
      </SideBarBody>
      <SideBarFooter>
        <Select
          style={{
            width: "100%",
            textAlign: "center",
            height: "3.375rem",
            fontSize: "1.125rem",
            color: theme.colors?.blue?.[6],
          }}
          defaultValue={"ko"}
          data={[
            { value: "ko", label: "한국어" },
            { value: "en", label: "English" },
          ]}
          onChange={(value) => changeLanguage(value ?? "")} // onChange 이벤트 핸들러 추가
        />
        <Button
          style={{
            backgroundColor: "inherit",
            border: `1px solid ${theme.colors?.blue?.[6]}`,
            padding: "0.0625rem 1.625rem",
            textAlign: "center",
            height: "3.375rem",
            fontSize: "1.125rem",
            color: theme.colors?.blue?.[6],
          }}
          onClick={handleLogout}
          p={"0.0625rem 1.625rem"}
        >
          {t("로그 아웃")}
        </Button>
      </SideBarFooter>
    </nav>
  );
};

const SideBarHeader = styled.div`
  display: flex;
  width: 100%;
  height: 3.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: inherit;
`;

const SideBarBody = styled.div`
  display: flex;
  width: 100%;
  padding: 0.75rem 0.6rem;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  flex: 1 0 0;
  align-self: stretch;
  background-color: inherit;
`;

const SideBarFooter = styled.div`
  display: flex;
  width: 100%;
  height: 7rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  background-color: inherit;
`;

export default Sidebar;
