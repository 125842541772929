import { Input, Button, Flex } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { useModal } from "context/ModalStackManager";
import { customNotification } from "utils/notificationShow";

export const BarcodeInputForm = () => {
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const [inputs, setInputs] = useState<string[]>(['']);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Enter') { // Enter 키를 눌렀을 때만 실행
      event.preventDefault();
      const newValue = inputs[index].trim(); // 현재 입력값 가져오기
  
      if (newValue === '') {
        return; // 빈 값은 추가하지 않음
      }
  
      // 중복 검사
      if (inputs.some((input, i) => input.trim() === newValue && i !== index)) {
        customNotification.error({ message: newValue + t("번은 이미 추가된 LOT 입니다.") });
        setInputs((prevInputs) => {
          const updatedInputs = [...prevInputs];
          updatedInputs[index] = ''; // 중복된 값 비우기
          return updatedInputs;
        });
        return;
      }
  
      setInputs((prevInputs) => [...prevInputs, '']); // 새로운 입력 필드 추가
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInputs = [...inputs];
    newInputs[index] = event.target.value;
    setInputs(newInputs);
  };

  const handleRemoveInput = (index: number) => {
    setInputs((prevInputs) => prevInputs.filter((_, i) => i !== index));
    inputRefs.current = inputRefs.current.filter((_, i) => i !== index);
  };

  useEffect(() => {
    setInputs(['']);
    inputRefs.current = [];
  }, []);

  useEffect(() => {
    if (inputRefs.current.length > 0) {
      inputRefs.current[inputRefs.current.length - 1]?.focus();
    }
  }, [inputs]);

  const handleReturnBarcodeInputs = () => {
    const values = inputs.filter((input) => input !== '');
    closeModal(values);
  };

  return (
    <Flex direction="column" gap="sm">
      {inputs.map((input, index) => (
        <InputWrapper key={index}>
        <InputLabel>{index + 1}</InputLabel>
        <Input
            value={input}
            placeholder={t("Enter Input And Take a Barcode")}
            aria-label="Enter Input And Take a Barcode"
            data-autofocus
            onKeyDown={(event) => handleKeyDown(event, index)}
            onChange={(event) => handleChange(event, index)}
            style={{ marginBottom: '10px' }}
            ref={(el) => (inputRefs.current[index] = el)}
          />
          {index !== 0 && (
            <Button leftIcon={<IconTrash />} color="red" variant="white" onClick={() => handleRemoveInput(index)}>
              {t("삭제")}
            </Button>
          )}
        </InputWrapper>
      ))}
      <ButtonContainer>
        <Button onClick={handleReturnBarcodeInputs}>{t("투입")}</Button>
        <Button onClick={() => closeModal([])}>{t("닫기")}</Button>
      </ButtonContainer>
    </Flex>
  );
};

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 20px;
`;

const InputLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 10px;
`;