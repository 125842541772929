import styled from "@emotion/styled";
import { Button, Input, Table, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  AuthSignupPost201Response,
  WmsBatchPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import useInventoriesGetQuery from "api/inventories/useInventoriesGetQuery";
import useInventoryMutation from "api/inventories/useInventoriesMutationQuery";
import { LocationAutoComplete } from "components/common/autoComplete/location/location-autoComplate";
import { MultiInventoryBarcodeInput } from "components/common/barcode/inventory/multiInventoriesBarcodeInput";
import { HeaderSubTitle } from "components/common/standard/SubTitle";
import { HeaderTitle } from "components/common/standard/Title";
import { useModal } from "context/ModalStackManager";
import { useTranslation } from "react-i18next";
import { customNotification } from "utils/notificationShow";
import { BulkAllFormRow } from "./BulkAllFormRow";
import { useEffect, useRef, useState } from "react";

export interface OutgoingAllFormItemProps {
  formData: {
    lotId: number;
    lotName?: string;
    itemCode?: string;
    itemName?: string;
    spec?: string;
    quantity?: string;
    locationCode?: string;
  };
  seq: number;
  onChange: (index: number, quantity: string) => void;
  onDelete: (index: number) => void;
}

interface OutgoingAllFormProps extends WmsBatchPostRequest {
  barcodeInput: string | undefined;
  lotId: string | undefined;
  items: {
    lotId: number;
    lotName?: string;
    itemCode?: string;
    itemName?: string;
    spec?: string;
    quantity?: string;
    locationCode?: string;
  }[];
}

export interface inventoryUserDataProps {
  userData: AuthSignupPost201Response | undefined;
}
export const OutgoingAllForm = (props: inventoryUserDataProps) => {
  const { userData } = props;

  const locationCode = userData?.fromLocationCode;

  const form = useForm<Partial<OutgoingAllFormProps>>({
    initialValues: {
      barcodeInput: undefined,
      fromLocationCode: locationCode ?? undefined,
      lotId: undefined,
      items: [],
      additional: {},
    },
  });

  const { mutate: createMutate } = useInventoryMutation("outgoingAll");

  const { closeModal } = useModal();
  const { t } = useTranslation();

  const onSubmit = () => {
    createMutate(
      {
        wmsBatchPostRequest: {
          fromLocationCode: form.values.fromLocationCode as string,
          items: form.values.items?.map((item) => ({
            lotId: Number(item.lotId),
            quantity: item.quantity,
          })),
          additional: { ...form.values.additional },
        },
      },
      {
        onSuccess: (res: { status: number }) => {
          if (res.status === 200) {
            alert(t("벌크 출고에 성공하였습니다."));
            closeModal(form.values);
          }
        },
        onError: (error: any) => {
          customNotification.error({
            message:
              error?.response?.data?.message ??
              t("벌크 출고에 실패하였습니다."),
          });
        },
      }
    );
  };

  const previousBarcodeInput = useRef<string | undefined>(undefined);
  const [pendingBarcodeInputs, setPendingBarcodeInputs] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const { data, isFetching } = useInventoriesGetQuery(
    pendingBarcodeInputs.length > 0
      ? {
          query: {
            $and: [
              { lotId: { $eq: pendingBarcodeInputs[0] } },
              { locationCode: { $eq: form.values.fromLocationCode } },
              { quantity: { $gt: 0 } },
            ],
          },
        }
      : undefined
  );

  useEffect(() => {
    if (!data || isFetching || pendingBarcodeInputs.length === 0 || isProcessing) return;

    setIsProcessing(true);
    const nextBarcode = pendingBarcodeInputs[0];

    form.setFieldValue("lotId", nextBarcode);
    previousBarcodeInput.current = nextBarcode;

    const lotData = data.data.rows?.[0];

    if (!lotData) {
      customNotification.error({ message: nextBarcode + t("번 바코드에 해당하는 로트가 존재하지 않습니다.") });

      form.setFieldValue("barcodeInput", ""); // input 초기화
      setPendingBarcodeInputs((prev) => prev.slice(1)); // 큐에서 제거
      setIsProcessing(false);
      return;
    }

    const { lot, itemCode, itemName, spec, quantity, lotId } = lotData;

    const existingLot = form.values.items?.find((item) => Number(item.lotId) === Number(lotId));

    if (!existingLot) {
      form.insertListItem("items", { lotId, lotName: lot?.name, itemCode, itemName, spec, quantity });

      setTimeout(() => {
        form.setFieldValue("barcodeInput", "");
        form.setFieldValue("lotId", lotId?.toString());
        setPendingBarcodeInputs((prev) => prev.slice(1));
        setIsProcessing(false);
      }, 200);
    } else {
      customNotification.error({ message: nextBarcode + t("번은 이미 추가된 LOT 입니다.") });

      form.setFieldValue("barcodeInput", ""); // 중복 LOT일 때 input 초기화
      setPendingBarcodeInputs((prev) => prev.slice(1)); // 중복된 경우 큐에서 제거
      setIsProcessing(false);
    }
  }, [data, isFetching, pendingBarcodeInputs, isProcessing]);

  const handleRowChange = (index: number, quantity: string) => {
    form.setFieldValue(`items.${index}.quantity`, quantity);
  };

  const handleRowDelete = (index: number) => {
    form.removeListItem("items", index);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (!locationCode && !form.values.fromLocationCode) {
        customNotification.information({
          message: t("사용자의 로케이션 또는 출고 로케이션을 선택해주세요."),
        });
        return;
      }
      const newValue = e.currentTarget?.value ?? "";
      if (!newValue.trim()) return;

      if (!pendingBarcodeInputs.includes(newValue)) {
        setPendingBarcodeInputs((prev) => [...prev, newValue]);
      }
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!locationCode && !form.values.fromLocationCode) {
      customNotification.information({
        message: t("사용자의 로케이션 또는 출고 로케이션을 선택해주세요."),
      });
      return;
    }

    const hasExistingLot = form.values.items?.find(
      (item) => Number(item.lotId) === Number(form.values.barcodeInput)
    );

    if (hasExistingLot) {
      customNotification.error({ message: t("이미 추가된 LOT 입니다.") });
      form.setFieldValue("barcodeInput", "");
      form.setFieldValue("lotId", "");
      return;
    }
    const inputValue = event.target.value;
    form.setFieldValue("lotId", inputValue);
  };

  return (
    <OutgoingAllFormContainer>
      <TitleHeader>
        <HeaderTitle>{t("바코드 LOT 입력")}</HeaderTitle>
        <HeaderSubTitle>
          {t("바코드를 스캔하여 자동으로 데이터를 입력합니다.")} <br />{" "}
          {t("등록 버튼을 클릭하여 일괄 출고를 완료합니다.")}
        </HeaderSubTitle>
      </TitleHeader>
      {locationCode !== null ? (
        <BarcodeInputContainer>
          <LocationContainer>
            <LocationAutoComplete
              size="xl"
              dropdownPosition="bottom"
              defaultValue={locationCode}
              label={t("출고로케이션")}
              {...form.getInputProps("fromLocationCode")}
            />
          </LocationContainer>
          <Input.Wrapper label={t("바코드 입력 필드")} size="xl">
            <MultiInventoryBarcodeInput
              handleBlur={handleBlur}
              data-autofocus
              onKeyDown={handleKeyDown}
              {...form.getInputProps("barcodeInput")}
            />
          </Input.Wrapper>
        </BarcodeInputContainer>
      ) : locationCode === null ? (
        <BarcodeInputContainer>
          <LocationContainer>
            <LocationAutoComplete
              size="xl"
              data-autofocus
              dropdownPosition="bottom"
              label={t("출고로케이션")}
              {...form.getInputProps("fromLocationCode")}
            />
          </LocationContainer>
          <Input.Wrapper label={t("바코드 입력 필드")} size="xl">
            <MultiInventoryBarcodeInput
              handleBlur={handleBlur}
              onKeyDown={handleKeyDown}
              {...form.getInputProps("barcodeInput")}
            />
          </Input.Wrapper>
        </BarcodeInputContainer>
      ) : (
        ""
      )}
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <Th width={7}>
                <Text size="2.5rem">{t("시퀀스")}</Text>
              </Th>
              <Th width={14}>
                <Text size="2.5rem">LOT ID</Text>
              </Th>
              <Th width={14}>
                <Text size="2.5rem">{t("LOT명")}</Text>
              </Th>
              <Th width={14}>
                <Text size="2.5rem">{t("품목코드")}</Text>
              </Th>
              <Th width={14}>
                <Text size="2.5rem">{t("품목명")}</Text>
              </Th>
              <Th width={14}>
                <Text size="2.5rem">{t("규격")}</Text>
              </Th>
              <Th width={14}>
                <Text size="2.5rem">{t("수량")}</Text>
              </Th>
              <Th width={7}>&nbsp;</Th>
            </tr>
          </thead>
          <tbody>
            {form.values.items?.map((formData, index) => (
              <BulkAllFormRow
                formData={formData}
                seq={index}
                key={formData.lotId + index}
                onChange={handleRowChange}
                onDelete={handleRowDelete}
              />
            ))}
            {form.values.items && form.values.items.length === 0 && (
              <tr>
                <Td
                  colSpan={8}
                  width={100}
                  style={{ textAlign: "center", fontSize: "3rem" }}
                >
                  {t("바코드를 스캔하여 LOT를 등록하세요.")}
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableWrapper>
      <ButtonBox>
        <Button size="xl" color="gray" onClick={closeModal}>
          {t("취소")}
        </Button>
        <Button size="xl" onClick={onSubmit}>
          {t("벌크 출고")}
        </Button>
      </ButtonBox>
    </OutgoingAllFormContainer>
  );
};

const OutgoingAllFormContainer = styled.div`
  display: flex;
  width: 100rem;
  min-height: 40rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const TitleHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const BarcodeInputContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  align-items: flex-end;
  gap: 10px;
  flex-shrink: 0;
`;

const LocationContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;
  flex: 1 0 0;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
`;

const ButtonBox = styled.div`
  display: flex;
  height: 44px;
  padding: 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
`;

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
`;

export const Td = styled.td<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
`;
