import { Button, Select, SelectItem, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useAuthWhoamiGet } from "api/useAuthWhoamiGet";
import useUsersMutationQuery from "api/user/useUserMutation";
import { users } from "api/user/useUserQuery";
import { EquipmentAutoComplete } from "components/common/autoComplete/equipment/equipment-autoComplete";
import { LocationAutoComplete } from "components/common/autoComplete/location/location-autoComplate";
import { useLocalStorage } from "context/LocalStorageProvider";
import { useModal } from "context/ModalStackManager";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { FormButtonBox } from "../FormButtonBox";
import { FormMain } from "../FormMain";
import { FormWrapper } from "../FormWrapper";

const User = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

export const UserUpdate = () => {
  const { closeModal } = useModal();
  const {
    userId,
    userName,
    name,
    equipmentCode,
    setEquipmentCode,
    setWorkId,
    fromLocationCode,
    setFromLocationCode,
    toLocationCode,
    setToLocationCode,
    setPrintCode,
    printCode,
  } = useLocalStorage();

  const form = useForm({
    initialValues: {
      id: userId, // pk
      name: name, // 이름
      username: userName, // 아이디
      equipmentCode: equipmentCode, //설비코드
      fromLocationCode: fromLocationCode,
      toLocationCode: toLocationCode,
      printCode: printCode,
    },
  });
  const { t } = useTranslation();

  const { queryKey, queryFn } = users.getSelect({});
  const { data } = useQuery(queryKey, queryFn, {
    select: (data) =>
      data
        ?.filter(
          (user, index, self) =>
            user?.printerCode !== "" &&
            user?.printerCode !== null &&
            index === self.findIndex((t) => t.printerCode === user.printerCode)
        )
        .map((user) => {
          return {
            value: user?.printerCode as string,
            label: user?.printerCode as string,
          };
        }),
  });

  const { refetch } = useAuthWhoamiGet({
    onSuccess: (data) => {},
  });
  const { mutate: updateMutate } = useUsersMutationQuery("update");

  const onSubmit = () => {
    try {
      updateMutate(
        {
          userId: form.values.id as number,
          usersUserIdDeleteRequest: {
            name: form.values.name,
            username: form.values.username,
            equipmentCode: form.values.equipmentCode,
            fromLocationCode:
              form.values.fromLocationCode === "null"
                ? null
                : form.values.fromLocationCode,
            toLocationCode:
              form.values.toLocationCode === "null"
                ? null
                : form.values.toLocationCode,
            printerCode: form.values.printCode ?? null,
          },
        },
        {
          onSuccess: (res) => {
            if (res.status === 200) {
              alert(t("사용자 수정 성공"));
              refetch();
              setWorkId(undefined);
              setEquipmentCode(form.values.equipmentCode);
              setFromLocationCode(form.values.fromLocationCode);
              setToLocationCode(form.values.toLocationCode);
              setPrintCode(form.values.printCode);
              closeModal({});
            }
          },
        }
      );
    } catch (e) {
      alert(t("실패"));
    }
  };

  return (
    <User.Wrapper>
      <User.Input
        size="xl"
        label={t("사용자 ID")}
        defaultValue={form.values?.username}
        disabled
        styles={{ label: { fontSize: "0.9em" } }}
      />
      <User.Input
        size="xl"
        label={t("사용자 이름")}
        defaultValue={form.values?.name}
        disabled
        styles={{ label: { fontSize: "0.9em" } }}
      />
      <EquipmentAutoComplete
        size="xl"
        label={t("설비")}
        defaultValue={form.values.equipmentCode}
        styles={{ label: { fontSize: "0.9em" } }}
        {...form.getInputProps("equipmentCode")}
      />
      <LocationAutoComplete
        size="xl"
        label={t("출고 로케이션 코드")}
        defaultValue={form.values.fromLocationCode}
        styles={{ label: { fontSize: "0.9em" } }}
        {...form.getInputProps("fromLocationCode")}
      />
      <LocationAutoComplete
        size="xl"
        label={t("입고 로케이션 코드")}
        defaultValue={form.values.toLocationCode}
        styles={{ label: { fontSize: "0.9em" } }}
        {...form.getInputProps("toLocationCode")}
      />
      <Select
        size="xl"
        label={t("프린터")}
        clearable
        defaultValue={form.values.printCode}
        styles={{ label: { fontSize: "0.9em" } }}
        {...form.getInputProps("printCode")}
        data={(data as (string | SelectItem)[]) ?? []}
        withinPortal
      />
      <User.ButtonBox>
        <User.Button color="gray" size="lg" onClick={closeModal}>
          {t("취소")}
        </User.Button>
        <User.Button onClick={onSubmit} size="lg">
          {t("등록")}
        </User.Button>
      </User.ButtonBox>
    </User.Wrapper>
  );
};
