import styled from "@emotion/styled";
import { Button, Flex, Loader, Table, Text, TextInput } from "@mantine/core";
import { useForm, UseFormReturnType } from "@mantine/form";
import { ProductionActionApiWorksBulkInputPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import useInventoriesGetQuery from "api/inventories/useInventoriesGetQuery";
import {
  INVENTORIES_KEY,
  mutateInventories,
} from "api/inventories/useInventoriesQuery";
import { WORK_LOGS_KEY } from "api/logs/useWorksLogsQuery";
import { InventoriesAutoComplete } from "components/common/autoComplete/inventory/inventories-autoComplete";
import { MultiInventoryBarcodeInput } from "components/common/barcode/inventory/multiInventoriesBarcodeInput";
import { HeaderSubTitle } from "components/common/standard/SubTitle";
import { HeaderTitle } from "components/common/standard/Title";
import { customFunctions } from "config/customFunctions";
import { useModal } from "context/ModalStackManager";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { customNotification } from "utils/notificationShow";
import { RawMaterialFormRow } from "./RawMaterialFormRow";

export interface WorkInputFormProps {
  workData: any;
  lotId?: number;
  onInputSuccess?: () => void;
}

interface WorkBulkInputData {
  lotId: number;
  itemCode?: string;
  itemName?: string;
  lotName?: string;
  lotExpireDate?: string;
  locationCode?: string;
  quantity?: string;
}

export interface RawMaterialInputAllFormProps {
  formData: WorkBulkInputData;
  seq: number;
  onChange: (index: number, quantity: string) => void;
  onDelete: (index: number) => void;
}

export interface RoutingBomProps {
  itemCode: string;
  routingCode: string;
  item: {
    name: string;
  };
}

interface FormValues {
  barcodeInput: string;
  lotId: string;
  worksBulkInputPutRequest: WorkBulkInputData[];
}

export const RawMaterialInputAllForm = ({
  workData,
  onInputSuccess,
}: WorkInputFormProps) => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [sourceLocationCode, setSourceLocationCode] = useState("");
  const [targetLocationCode, setTargetLocationCode] = useState("");

  const { mutate: InventoriesAllMutate, isLoading } = useMutation(
    (params: ProductionActionApiWorksBulkInputPutRequest) =>
      mutateInventories.workInputAll(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(INVENTORIES_KEY);
        queryClient.invalidateQueries(WORK_LOGS_KEY);
      },
    }
  );

  // 바코드 스캔했을 때, lotId에 대해서 데이터 처리를 어떻게 할 것인지를 파악하고 로직 구성하면 됨.

  const form: UseFormReturnType<FormValues> = useForm<FormValues>({
    initialValues: {
      barcodeInput: "",
      lotId: "",
      worksBulkInputPutRequest: [],
    },
  });

  const previousBarcodeInput = useRef<string | undefined>(undefined);
  const [pendingBarcodeInputs, setPendingBarcodeInputs] = useState<string[]>(
    []
  );
  const [isProcessing, setIsProcessing] = useState(false);

  const { data, isFetching } = useInventoriesGetQuery(
    pendingBarcodeInputs.length > 0
      ? {
          query: {
            $and: [
              {
                itemCode: workData?.routingData?.routingBoms?.map(
                  (value: RoutingBomProps) => value.itemCode
                ),
              },
              {
                lotId: { $eq: pendingBarcodeInputs[0] },
              },
              {
                locationCode:
                  workData?.locationSetting?.fromLocation?.code ??
                  workData?.routingData?.operation?.fromLocationCode ??
                  workData?.productionPlan?.routingsData.find(
                    (data: any) => data.code === workData.routingCode
                  ).operation.fromLocationCode,
              },
              {
                quantity: { $gt: 0 },
              },
            ],
          },
        }
      : undefined
  );

  useEffect(() => {
    if (
      !data ||
      isFetching ||
      pendingBarcodeInputs.length === 0 ||
      isProcessing
    )
      return;

    setIsProcessing(true);
    const nextBarcode = pendingBarcodeInputs[0];

    form.setFieldValue("lotId", nextBarcode ?? ""); // undefined 처리
    previousBarcodeInput.current = nextBarcode;

    const lotData = data.data.rows?.[0];

    if (!lotData) {
      customNotification.error({
        message:
          nextBarcode + t("번 바코드에 해당하는 로트가 존재하지 않습니다."),
      });

      form.setFieldValue("barcodeInput", ""); // input 초기화
      setPendingBarcodeInputs((prev) => prev.slice(1)); // 큐에서 제거
      setIsProcessing(false);
      return;
    }

    const { lot, itemCode, itemName, locationCode, quantity, lotId } = lotData;

    const existingLot = form.values.worksBulkInputPutRequest?.find(
      (item) => Number(item.lotId) === Number(lotId)
    );

    if (!existingLot) {
      form.insertListItem("worksBulkInputPutRequest", {
        lotId,
        lotName: lot?.name,
        itemCode,
        itemName,
        locationCode,
        quantity,
      });

      setTimeout(() => {
        form.setFieldValue("barcodeInput", "");
        form.setFieldValue("lotId", lotId?.toString() ?? ""); // undefined 처리
        setPendingBarcodeInputs((prev) => prev.slice(1));
        setIsProcessing(false);
      }, 200);
    } else {
      customNotification.error({
        message: nextBarcode + t("번은 이미 추가된 LOT 입니다."),
      });

      form.setFieldValue("barcodeInput", ""); // 중복 LOT일 때 input 초기화
      setPendingBarcodeInputs((prev) => prev.slice(1)); // 중복된 경우 큐에서 제거
      setIsProcessing(false);
    }
  }, [data, isFetching, pendingBarcodeInputs, isProcessing]);

  useEffect(() => {
    setSourceLocationCode(
      workData?.locationSetting?.fromLocation?.code ??
        workData?.currentRoutingOutsourceData?.fromLocationCode ??
        workData?.routingData?.operation?.fromLocationCode
    );
    setTargetLocationCode(
      // workData?.currentRoutingOutsourceData?.toLocationCode ??
      workData?.equipment?.toLocationCode ??
        workData?.locationSetting?.toLocation?.code ??
        workData?.routingData?.operation?.toLocationCode ??
        workData?.productionPlan?.routingsData.find(
          (data: any) => data.code === workData.routingCode
        ).operation.toLocationCode
    );
  }, [workData]);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (!inputValue) return;
    if (!checkDuplicateLot(inputValue))
      return form.setFieldValue("lotId", inputValue as string);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const inputValue = e.currentTarget.value;
      if (!inputValue.trim()) return;
      if (!pendingBarcodeInputs.includes(inputValue)) {
        setPendingBarcodeInputs((prev) => [...prev, inputValue]);
      }
    }
  };

  const handleRowChange = (index: number, quantity: string) => {
    form.setFieldValue(`worksBulkInputPutRequest.${index}.quantity`, quantity);
  };

  const handleRowDelete = (index: number) => {
    form.removeListItem("worksBulkInputPutRequest", index);
  };

  const onSubmit = () => {
    try {
      const request = form.values.worksBulkInputPutRequest.map(
        (data: WorkBulkInputData) => ({
          workId: workData.id,
          lotId: data.lotId,
          sourceLocationCode,
          targetLocationCode,
          quantity: data.quantity,
        })
      );

      InventoriesAllMutate(
        {
          worksBulkInputPutRequest: {
            workLotPairs: request,
          },
        },
        {
          onSuccess: () => {
            customNotification.success({
              message: t("원/부자재가 성공적으로 투입되었습니다."),
            });
            onInputSuccess && onInputSuccess(); // 실적 저장시 원부자재 투입이 된다면 실적저장 함수를 실행
            closeModal(form.values);
          },
          onError: (error: any) => {
            customNotification.error({
              message:
                error?.response?.data?.message ??
                t("원/부자재 투입에 실패하였습니다."),
            });
          },
        }
      );
    } catch (e) {
      customNotification.error({
        message: t("원/부자재 투입에 실패하였습니다."),
      });
    }
  };

  const checkDuplicateLot = (value: string) => {
    if (
      form.values.worksBulkInputPutRequest.some(
        (data: WorkBulkInputData) => data.lotId === Number(value)
      )
    ) {
      customNotification.error({ message: "이미 추가된 LOT입니다." });
      return true;
    }
    return false;
  };

  const itemCodes = workData?.routingData?.routingBoms?.map(
    (value: RoutingBomProps) => value.itemCode
  );

  if (isLoading)
    return (
      <Flex w="100%" h="100%" justify="center" align="center">
        <Loader size="30rem" />
      </Flex>
    );

  return (
    <Flex w="80rem" direction="column" gap="md" mah="50rem">
      <Flex direction="column">
        <HeaderTitle>{t("바코드 LOT 입력")}</HeaderTitle>
        <HeaderSubTitle>
          {t("바코드를 스캔하여 자동으로 데이터를 입력합니다.")} <br />{" "}
          {t("일괄 투입 버튼을 클릭하여 원부자재를 일괄 투입합니다.")}
        </HeaderSubTitle>
      </Flex>
      <Flex
        direction={
          customFunctions.ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING
            ? "column"
            : "row"
        }
        justify="space-between"
        gap="md"
      >
        {customFunctions.ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING && (
          <InventoriesAutoComplete
            styles={{
              label: { fontSize: "1.5rem" },
            }}
            // key={form.values.worksWorkIdInputPutRequest.lotId}
            label={t("로트 정보")}
            query={{
              $and: [
                {
                  itemCode: Array.isArray(itemCodes) ? itemCodes : [itemCodes],
                },
                {
                  locationCode:
                    workData?.routingData?.operation?.fromLocationCode ??
                    workData?.productionPlan?.routingsData.find(
                      (data: any) => data.code === workData.routingCode
                    ).operation.fromLocationCode,
                },
                {
                  quantity: { $gt: 0 },
                },
              ],
            }}
            value={form.values.lotId}
            onChange={(value) => {
              //form에 이미 같은 lotId가 존재할 경우, 추가하지 않음
              if (value === null) return;
              if (!checkDuplicateLot(value))
                return form.setFieldValue("lotId", value as string);
            }}
            size="lg"
          />
        )}
        <MultiInventoryBarcodeInput
          handleBlur={handleBlur}
          onKeyDown={handleKeyDown}
          {...form.getInputProps("barcodeInput")}
        />
        <Flex gap="md">
          <TextInput
            size="xl"
            label={<Text fz="1.5rem">{t("출고로케이션")}</Text>}
            disabled
            value={sourceLocationCode}
          />
          <TextInput
            size="xl"
            label={<Text fz="1.5rem">{t("입고로케이션")}</Text>}
            disabled
            value={targetLocationCode}
          />
        </Flex>
      </Flex>
      <Table>
        <thead>
          <tr>
            <Th width={7}>
              <Text fz="2rem">{t("순서")}</Text>
            </Th>
            <Th width={17}>
              <Text fz="2rem">{t("품목코드")}</Text>
            </Th>
            <Th width={17}>
              <Text fz="2rem">{t("품목명")}</Text>
            </Th>
            <Th width={14}>
              <Text fz="2rem">{t("로트명")}</Text>
            </Th>
            <Th width={18}>
              <Text fz="2rem">{t("로트유효기한")}</Text>
            </Th>
            <Th width={13}>
              <Text fz="2rem">{t("로트 위치")}</Text>
            </Th>
            <Th width={14}>
              <Text fz="2rem">{t("수량")}</Text>
            </Th>
            <Th width={8}>&nbsp;</Th>
          </tr>
        </thead>
        <tbody>
          {form.values.worksBulkInputPutRequest?.map(
            (formData: WorkBulkInputData, index) => (
              <RawMaterialFormRow
                formData={formData}
                seq={index}
                key={formData.lotId + index}
                onChange={handleRowChange}
                onDelete={handleRowDelete}
              />
            )
          )}
          {form.values.worksBulkInputPutRequest &&
            form.values.worksBulkInputPutRequest.length === 0 && (
              <tr>
                <Td colSpan={7} width={100}>
                  <Text ta="center" fz="2rem">
                    {t(
                      "바코드 스캔 또는 로트정보를 입력하여 LOT를 등록하세요."
                    )}
                  </Text>
                </Td>
              </tr>
            )}
        </tbody>
      </Table>
      <Flex justify="flex-end" gap="md">
        <Button size="xl" color="gray" onClick={() => closeModal({})}>
          {t("취소")}
        </Button>
        <Button
          size="xl"
          disabled={form.values.worksBulkInputPutRequest.length === 0}
          onClick={onSubmit}
        >
          {t("일괄 투입")}
        </Button>
      </Flex>
    </Flex>
  );
};

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
`;

export const Td = styled.td<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
`;
