import styled from "@emotion/styled";
import { Flex, Table } from "@mantine/core";
import { WlWithEslViewGet200ResponseRowsInnerActionParamParamsItemsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { Tbody } from "pages/common/wmsLogs";

interface WmsTableRowProps {
  lotInfo:
    | WlWithEslViewGet200ResponseRowsInnerActionParamParamsItemsInner[]
    | undefined;
}

export const WmsTableRow = (params: WmsTableRowProps) => {
  const { lotInfo } = params;

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>품목코드</th>
            <th>품목명</th>
            <th>규격</th>
            <th>로트명</th>
            <th>수량</th>
          </tr>
        </thead>
        <Tbody>
          {lotInfo?.length && lotInfo?.length > 0 ? (
            lotInfo?.map((lot) => (
              <Tr key={lot?.lotInfo?.id} style={{ color: "white" }}>
                <td></td>
                <td>{lot?.lotInfo?.itemCode}</td>
                <td>{lot.lotInfo?.item?.name}</td>
                <td>{lot?.lotInfo?.item?.spec}</td>
                <td>{lot?.lotInfo?.name}</td>
                <td>{lot.quantity}</td>
              </Tr>
            ))
          ) : (
            <Tr>
              <td colSpan={4}>
                <Flex justify={"center"} align={"center"}>
                  데이터가 없습니다.
                </Flex>
              </td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 8px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  height: auto;
`;

const Tr = styled.tr`
  line-height: inherit;
  height: 50px;
`;
