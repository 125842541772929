import { Input, InputProps } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BarcodeInputForm } from "./barcodeInputForm";
import { useModal } from "context/ModalStackManager";

interface InventoryBarcodeInputProps extends InputProps {
    // value: string
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    value: string;    
    onChange: (value: string) => void;
}

export const MultiInventoryBarcodeInput = ({ onKeyDown, handleBlur, onChange, value, ...etcParams }: InventoryBarcodeInputProps) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(value);
  const [queuedValues, setQueuedValues] = useState<string[]>([]);

  // 입력값을 한 번에 처리하기 위해 queue에 저장
  const handleConfirm = (values: string[]) => {
    if (values?.length > 0) {
      setQueuedValues(values);
    }
  };

  useEffect(() => {
    if (queuedValues.length > 0) {
      const processNextValue = async (index: number) => {
        if (index >= queuedValues.length) return;

        const val = queuedValues[index];

        setInputValue(val); // 상태 업데이트
        onChange(val); // `onChange`를 실행하여 `barcodeInput` 값 업데이트

        setTimeout(() => {
          if (inputRef.current) {
            const event = new KeyboardEvent("keydown", { key: "Enter", bubbles: true });
            inputRef.current.dispatchEvent(event);
          }
        }, 50); // 상태 업데이트 반영 후 실행

        setTimeout(() => {
          processNextValue(index + 1); // 다음 값 처리
        }, 1000); // 다음 값까지 약간의 대기 시간 추가
      };

      processNextValue(0);
    }
  }, [queuedValues]);

  useEffect(() => {
      if (inputRef.current && inputValue) {
          inputRef.current.value = inputValue;
      }
  }, [inputValue]);

    return (
        <Input.Wrapper size="1.4rem" label={t("바코드 정보 입력")} auto-focus>
          <Input
            value={value}
            onClick={() => openModal(<BarcodeInputForm />, null, t("일괄 투입"), true).then((result) => {
              handleConfirm(result)
            })}
            onKeyDown={onKeyDown}
            aria-label="바코드정보"
            size="lg"
            data-autofocus
            ref={inputRef}
            placeholder="Enter Input And Take a Barcode"
            {...etcParams}
            onBlur={(e) => {
              etcParams.onBlur && etcParams.onBlur(e)
              handleBlur && handleBlur(e)
            }}
          />
        </Input.Wrapper>
    );
}